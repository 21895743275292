import { useEffect, useState } from "react";
import apiClient from "../../utils/axios";
import ReactPaginate from "react-paginate";

const MappingReport = () =>{

    const [factories,setFactories] = useState([])
    const [farms,setFarms] =useState([])

    const [pageNo, setPageNo] = useState(1)
    const limit = 10 ;
    const [isLoading,setIsLoading] = useState(true)
    const[members,setMembers] = useState([])
    const [originalFactoryList, setOriginalFactoryList] = useState();
    const[totalCount,setTotalCount] = useState(0)
    const[mappedFarmsCount,setMappedFarmsCount] = useState(0)
    const [name,setName] = useState("")
    const [mappedValue,setMappedValue] = useState(null)

    const [selectedFactoryId,setSelectedFactoryId] = useState(null)

    const [countTotalPage,setCountTotalPage] = useState(0)

    const [showMappedDropDown,setShowMappedDropDown] = useState(false)

    const getMemberName = (farm) =>{
        return farm.member.full_name
    }

    const pageHandler = (data) =>{
        setPageNo(data.selected + 1)
    }

    


    const handleFactoryCahnge = async (e) =>{
        let selectedFactoryId = e.target.value
        setSelectedFactoryId(selectedFactoryId)
        if(selectedFactoryId){
            let finalV = parseInt(selectedFactoryId)
            setShowMappedDropDown(true) //this is use to hide/show the mapped dropdown

            let region = factories.find(fact=>fact.id === finalV)
           if(region){
            setName(region.name)
           }
           
           await getFarmsByFactoryId(selectedFactoryId,1)
        }else{
            setShowMappedDropDown(false)
            return ;
        }
       
    }

    const getFarmsByFactoryId = async (selectedFactoryId,pageNumber) =>{
          //get the farms related to this factory
            
          let url = `v1/all-farms/${selectedFactoryId}?pageNo=${pageNumber}&limit=${limit}`

          const response = await apiClient.get(url)
          console.log("response",response.data)
          if(response.status ===  200){

              setIsLoading(false)

              setTotalCount(response.data.totalCount)
              setMappedFarmsCount(response.data.mappedFarmsCount)

              setCountTotalPage(response.data.totalCount)
              setFarms(response.data.data.data)
              setOriginalFactoryList(response.data.data.data)

          }
    }

   

    const handleMapFilter = async (e) =>{
        
        let mappedValue = e.target.value;
        if(!mappedValue) return ;
        if(!selectedFactoryId) return ;
        setPageNo(1)
        setMappedValue(mappedValue)
       

       //filter farms with mapped values from the already existing farms by factory 
 
       await getFarmsByMappedValue(selectedFactoryId,mappedValue,1)
      

    }

    const getFarmsByMappedValue = async (selectedFactoryId,mappedValue,pageNumber) =>{
        let url = `v1/all-farms-filter-by-mapped-value/${selectedFactoryId}/${mappedValue}?pageNo=${pageNumber}&limit=${limit}`

        const response = await apiClient.get(url)
        if(response.status ===  200){
            
            setIsLoading(false)
            setFarms(response.data.data.data)
            setCountTotalPage(response.data.totalCount)
            setOriginalFactoryList(response.data.data.data)

        }
    }

    const getData = async () => {

        const response = await apiClient.get(`v1/factories`)
        if(response.status === 200){
            setFactories(response.data)
        }
    }

    const getFarms = async () => {

        const response = await apiClient.get(`v1/farms?pageNo=${pageNo}&limit=${limit}`)
        setTotalCount(response.data.totalCount)
        setMappedFarmsCount(response.data.mappedFarmsCount)
        setFarms(response.data.data.data)
        setOriginalFactoryList(response.data.data.data)
    }

    useEffect(()=>{
         getData()
    },[])

    useEffect(()=>{
        if(!selectedFactoryId){
            getFarms()
         }
    },[pageNo])

    useEffect(()=>{
        if(selectedFactoryId && !mappedValue){
            getFarmsByFactoryId(selectedFactoryId,pageNo)
        }
    },[pageNo])

    useEffect(()=>{
        if(selectedFactoryId && mappedValue){
            getFarmsByMappedValue(selectedFactoryId,mappedValue,pageNo)
        }
    },[pageNo])
    

    
    


    return <>
    <div>
       
        {/* stats for the farms maped under these factories */}

        <div className="row g-gs mt-3">

            <div className="col-xxl-3 col-sm-6 mb-1">
                <div className="card" style={{ cursor: 'pointer' }} >
                    <div className="nk-ecwg nk-ecwg6">
                        <div className="card-inner">
                            <div className="card-title-group">
                                <div className="card-title">
                                    <h6 className="title mt-2 text-center">Total Farms</h6>
                                    <p className="text-center">{totalCount}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="col-xxl-3 col-sm-6 mb-1">
                <div className="card" style={{ cursor: 'pointer' }} >
                    <div className="nk-ecwg nk-ecwg6">
                        <div className="card-inner">
                            <div className="card-title-group">
                                <div className="card-title">
                                    <h6 className="title mt-2 text-center">Farms Mapped</h6>
                                    <p className="text-center">{mappedFarmsCount}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="col-xxl-3 col-sm-6 mb-1">
                <div className="card" style={{ cursor: 'pointer' }} >
                    <div className="nk-ecwg nk-ecwg6">
                        <div className="card-inner">
                            <div className="card-title-group">
                                <div className="card-title">
                                    <h6 className="title mt-2 text-center">Farms UnMapped</h6>
                                    <p className="text-center">{totalCount - mappedFarmsCount}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        

        <div className="mt-3">
           <div className="d-flex justify-content-between mb-3 gap-1">

           <div>
            <select className="form-select" onChange={handleFactoryCahnge}>
                    <option value={``}>Select Region</option>
                    {factories.map(factory=>(
                        <option value={factory.id}>{factory.name}</option>
                    ))}
                </select>
           </div>

            {showMappedDropDown && <div>
                    <select className="form-select" onChange={handleMapFilter}>
                        <option value={``}>Filter </option>
                        <option value="1">Mapped</option>
                        <option value="2">UnMapped</option>
                    </select>
                </div>}
           </div>

           <div> {name !== "" && <h3>All Farms for {name}</h3>}</div>

            <div className="table-responsive">
            <table className="table table-table table-hover">
                <thead className="thead">
                    <tr>
                        <td>Member Name</td>
                        <td>County</td>
                        <td>Mapped Status</td>
                        <td>Actions</td>
                    </tr>
                </thead>

                <tbody>
                    {farms.length > 0 && farms.map(farm=>(
                        <tr key={farm.id}>
                            <td>{getMemberName(farm)}</td>
                            <td>{farm?.county?.name}</td>
                            <td><span style={{color:'#fff',borderRadius:'5px'}} className={` ${farm.map_status === "yes" ? "bg-primary p-2" : "bg-secondary p-2"}`}>
                                {farm.map_status === "yes" ? "Mapped" : "Not Mapped"}
                            </span></td>
                            <td>View</td>
                        </tr>
                    ))}
                </tbody>
            </table>

            <div className="mt-3 mb-2">
                        <ReactPaginate
                            previousLabel={"previous"}
                            nextLabel={"next"}
                            breakLabel={"..."}
                            pageCount={(countTotalPage / 10)}
                            marginPagesDisplayed={3}
                            pageRangeDisplayed={3}
                            onPageChange={pageHandler}
                            containerClassName={"pagination justify-content-center"}
                            pageClassName={"page-item"}
                            pageLinkClassName={"page-link"}
                            previousClassName={"page-item"}
                            previousLinkClassName={"page-link"}
                            nextClassName={"page-item"}
                            nextLinkClassName={"page-link"}
                            breakClassName={"page-item"}
                            breakLinkClassName={"page-link"}
                            activeClassName={"active"}
                        /> 
            </div>
            </div>

        </div>

    </div>
    </>
}
export default MappingReport;
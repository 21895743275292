
import { React, useEffect, useState } from 'react';
import apiClient from '../../utils/axios';

import AllProductions from '../../components/productions/AllProductions';

const ListProductions = () => {

    const [pageNo, setPageNo] = useState(1)
    const [limit,setLimit] = useState(10)
    const [isLoading,setIsLoading] = useState(true)
    const [productions, setProductions] = useState([])
    const [count, setCount] = useState(0)
    
    const hadlePageNo = (number) => {
        setPageNo(number);
    }


    const getProductionsData = async () =>{
      
        let url = `v1/productions?pageNo=${pageNo}&limit=${limit}`

        const response = await apiClient.get(url)
        console.log("response",)
        if(response.status ===  200){
            setIsLoading(false)
            setProductions(response.data.data.data)
            setCount(response.data.totalCount)
        }

    }

    useEffect(()=>{
        const getData = async () =>{
            await getProductionsData()
        }
        getData()
    }, [pageNo])
    
    return isLoading ? <p>Loading ...</p> : <AllProductions productions={productions} count={count } pageNoHandler={hadlePageNo}/>
}

export default ListProductions;
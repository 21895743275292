import React, { useState } from 'react';
import axios from 'axios';
import apiClient from '../../utils/axios';
import Select from 'react-select';


const FarmForm = ({ cooperatives, factories, plantationTypes, varieties }) => {
    
    

    const varietiesOptions = varieties && varieties.length > 0 ?  varieties.map(variety => ({
        value: variety.id,
        label: variety.name
    })) : [];
    
    const plantationTypesOptions = plantationTypes && plantationTypes.length > 0 ?  plantationTypes.map(plantationType => ({
        value: plantationType.id,
        label: plantationType.name
    })):[];



    const [farms, setFarms] = useState([{
        cooperative_code: '',
        factory_code: '',
        gis_coordinates: '',
        gis_polygon: '',
        coffee_varieties: '',
        land_size: 0.00,
        land_near_forest: false,
        land_near_shopping_centre: false,
        member_type: '',
        plantation_type: [], // New field to indicate the type of plantation (coffee, mango, or both)
        hectares: 0.00, // New field for mango hectares
    }]);
    const [formData, setFormData] = useState({
        first_name: '',
        middle_name: '',
        last_name: '',
        id_number: '',
        gender: '',

    });
    const [errors, setErrors] = useState({});
    const [formStatus, setFormStatus] = useState('');
    const[isLoading,setIsLoading] =useState(false)

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevData => ({ ...prevData, [name]: value }));
    };

    const handleFarmChange = (index, e) => {
        const { name, value, type, checked } = e.target;
        const newFarms = [...farms];
        newFarms[index] = {
            ...newFarms[index],
            [name]: type === 'checkbox' ? checked : value
        };
        setFarms(newFarms);
    };

    const handlePlantationTypeChange = (index, selectedOptions) => {
        const newFarms = [...farms];
        const selectedValues = selectedOptions.map(option => option.value);

        newFarms[index] = {
            ...newFarms[index],
            plantation_type: selectedOptions,
            hectares: Object.fromEntries(
                selectedValues.map(value => [value, newFarms[index].hectares[value] || ''])
            ), // selectedOption will be the new value from react-select
        };
        setFarms(newFarms);
    };

    const handleHectareChange = (index, type, value) => {
            const newFarms = [...farms];
            newFarms[index].hectares[type] = value; // Update the hectares for the given type
            setFarms(newFarms);
    };
    


    const addFarmRow = () => {
        setFarms([...farms, {
            cooperative_code: '',
            factory_code: '',
            gis_coordinates: '',
            gis_polygon: '',
            coffee_varieties: '',
            land_size: 0.00,
            land_near_forest: false,
            land_near_shopping_centre: false,
            member_type: '',
            plantation_type: [], // New field to indicate the type of plantation (coffee, mango, or both)
            hectares: 0.00,
            member_type: '',
        }]);
    };

    const removeFarmRow = (index) => {
        setFarms(farms.filter((_, i) => i !== index));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        // Add validation logic here
        let data = { member: formData, farms }
        console.log("data", data)
        setIsLoading(true)
        
        try {
            // const response = await apiClient.post('v1/members', data)
            const response = await apiClient.post(`v1/members`, data);
           
            if (response.status === 200 && response.data.status) {
                setFormStatus('Farms added successfully!');
                setFormData({
                    first_name: '',
                    middle_name: '',
                    last_name: '',
                    id_number: '',
                    gender: '',
                    member_type: '',
                });
                setFarms([{
                    cooperative_code: '',
                    factory_code: '',
                    gis_coordinates: '',
                    gis_polygon: '',
                    coffee_varieties: '',
                    land_size: 0.00,
                    land_near_forest: false,
                    land_near_shopping_centre: false,
                    member_type: '',
                    plantation_type: [], // Reset plantation type
                    hectares: 0.00,
                }]);

                setFormStatus('Successfully saved Data');
            }
        } catch (error) {
            console.log("error",error.response)
            setFormStatus('Error adding farms.');
        } finally {
            setIsLoading(false)
        }
    }

      

    return (
        <div className="container mt-4">
            <form onSubmit={handleSubmit}>
                {/* Member Basic Information */}
                <div className="row">
                    <div className="col-md-6">
                        <div className="form-group">
                            <label htmlFor="first_name">First Name</label>
                            <input
                                type="text"
                                id="first_name"
                                name="first_name"
                                className="form-control"
                                placeholder="Enter First Name"
                                value={formData.first_name}
                                onChange={handleChange}
                            />
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="form-group">
                            <label htmlFor="middle_name">Middle Name</label>
                            <input
                                type="text"
                                id="middle_name"
                                name="middle_name"
                                className="form-control"
                                placeholder="Enter Middle Name"
                                value={formData.middle_name}
                                onChange={handleChange}
                            />
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-6">
                        <div className="form-group">
                            <label htmlFor="last_name">Last Name</label>
                            <input
                                type="text"
                                id="last_name"
                                name="last_name"
                                className="form-control"
                                placeholder="Enter Last Name"
                                value={formData.last_name}
                                onChange={handleChange}
                            />
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="form-group">
                            <label htmlFor="id_number">ID Number</label>
                            <input
                                type="text"
                                id="id_number"
                                name="id_number"
                                className="form-control"
                                placeholder="Enter ID Number"
                                value={formData.id_number}
                                onChange={handleChange}
                            />
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-6">
                        <div className="form-group">
                            <label htmlFor="gender">Gender</label>
                            <select
                                id="gender"
                                name="gender"
                                className="form-control"
                                value={formData.gender}
                                onChange={handleChange}
                            >
                                <option value="">Select Gender</option>
                                <option value="male">Male</option>
                                <option value="female">Female</option>
                            </select>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="form-group mt-3">
                             <button type="button" className="btn btn-primary" onClick={addFarmRow}>
                                Add Farm
                            </button>
                        </div>
                    </div>
                </div>
                <div className="my-4">
                   
                    {farms.map((farm, index) => (
                        <div key={index} className="mt-3 border p-3">
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label htmlFor={`cooperative_code_${index}`}>Cooperative Code</label>
                                        <select
                                            id={`cooperative_code_${index}`}
                                            name="cooperative_code"
                                            className="form-control"
                                            value={farm.cooperative_code}
                                            onChange={(e) => handleFarmChange(index, e)}
                                        >
                                            <option value="">Select Cooperative</option>
                                            {cooperatives.map(coop => (
                                                <option key={coop.code} value={coop.code}>
                                                    {coop.name}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label htmlFor={`factory_code_${index}`}>Factory Code</label>
                                        <select
                                            id={`factory_code_${index}`}
                                            name="factory_code"
                                            className="form-control"
                                            value={farm.factory_code}
                                            onChange={(e) => handleFarmChange(index, e)}
                                        >
                                            <option value="">Select Factory</option>
                                            {factories.map(factory => (
                                                <option key={factory.code} value={factory.code}>
                                                    {factory.name}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                </div>
                            </div>
                        <div className="row">
                            <div className="col-md-6">
                                    <div className="form-group">
                                        <label htmlFor={`member_type_${index}`}>Member Type</label>
                                        <select
                                            id={`member_type_${index}`}
                                            name="member_type"
                                            className="form-control"
                                            value={formData.member_type}
                                            onChange={(e) => handleFarmChange(index, e)}
                                        >
                                            <option value="">Select Member Type</option>
                                            <option value="cooperative">Cooperative</option>
                                            <option value="estate">Estate</option>
                                        </select>
                                    </div>
                                </div>
                                <div className='col-md-6'>
                                    <div className="form-group">
                                        <label htmlFor={`coffee_varieties_${index}`}>Coffee Varieties</label>
                                        <textarea
                                            id={`coffee_varieties_${index}`}
                                            name="coffee_varieties"
                                            className="form-control"
                                            placeholder="Enter Coffee Varieties"
                                            value={farm.coffee_varieties}
                                            onChange={(e) => handleFarmChange(index, e)}
                                        ></textarea>
                                    </div>
                                </div>
                                

                        </div>
                            
                            <div className="row">
                                <div className='col-md-6'>
                                    <div className="form-group">
                                        <label htmlFor={`gis_coordinates_${index}`}>GIS Coordinates</label>
                                        <input
                                            type="text"
                                            id={`gis_coordinates_${index}`}
                                            name="gis_coordinates"
                                            className="form-control"
                                            placeholder="Enter GIS Coordinates"
                                            value={farm.gis_coordinates}
                                            onChange={(e) => handleFarmChange(index, e)}
                                        />
                                    </div>
                                </div>
                                <div className='col-md-6'>
                                     <div className="form-group">
                                        <label htmlFor={`gis_polygon_${index}`}>GIS Polygon</label>
                                        <input
                                            type="text"
                                            id={`gis_polygon_${index}`}
                                            name="gis_polygon"
                                            className="form-control"
                                            placeholder="Enter GIS Polygon"
                                            value={farm.gis_polygon}
                                            onChange={(e) => handleFarmChange(index, e)}
                                        />
                                    </div>
                                </div>

                             </div>

                            
                           
                           
                            
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label htmlFor={`land_size_${index}`}>Total Land Size</label>
                                        <input
                                            type="number"
                                            id={`land_size_${index}`}
                                            name="land_size"
                                            className="form-control"
                                            placeholder="Enter Total Land Size (hectares)"
                                            value={farm.land_size}
                                            onChange={(e) => handleFarmChange(index, e)}
                                        />
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group">


                                        <label htmlFor={`plantation_type_${index}`}>Plantation Type</label>

                                        <Select
                                            id={`plantation_type_${index}`}
                                            name="plantation_type"
                                            options={plantationTypesOptions}
                                            value={farm.plantation_type}
                                            onChange={(selectedOption) => handlePlantationTypeChange(index, selectedOption)}
                                            placeholder="Select Plantation Type.."
                                            isMulti={true} // Set to true if you want multiple selection
                                        />
                                        

                                       
                                    </div>
                                </div>
                            </div>

                            <div>
                                 {farm.plantation_type.map(option => (
                                    <div key={option.value} className="form-group">
                                        <label htmlFor={`hectares_${option.value}_${index}`}>
                                            Hectares for {option.label}:
                                        </label>
                                        <input
                                            type="number"
                                            id={`hectares_${option.value}_${index}`}
                                            name={`hectares_${option.value}`}
                                            className="form-control"
                                            placeholder={`Enter hectares for ${option.label}`}
                                            value={farm.hectares[option.value] || ''}
                                            onChange={(e) => handleHectareChange(index, option.value, e.target.value)}
                                        />
                                    </div>
                                ))}
                            </div>

                             <div className="col-md-6">
                                    <div className="form-group form-check">
                                        <input
                                            type="checkbox"
                                            id={`land_near_forest_${index}`}
                                            name="land_near_forest"
                                            className="form-check-input"
                                            checked={farm.land_near_forest}
                                            onChange={(e) => handleFarmChange(index, e)}
                                        />
                                        <label className="form-check-label" htmlFor={`land_near_forest_${index}`}>
                                            Land Near Forest
                                        </label>
                                    </div>
                            </div>

                                {/* Checkbox for Land Near Shopping Centre */}
                                <div className="col-md-6">
                                    <div className="form-group form-check">
                                        <input
                                            type="checkbox"
                                            id={`land_near_shopping_centre_${index}`}
                                            name="land_near_shopping_centre"
                                            className="form-check-input"
                                            checked={farm.land_near_shopping_centre}
                                            onChange={(e) => handleFarmChange(index, e)}
                                        />
                                        <label className="form-check-label" htmlFor={`land_near_shopping_centre_${index}`}>
                                            Land Near Shopping Centre
                                        </label>
                                    </div>
                            </div>     
                            
                            <button type="button" className="btn btn-danger mt-2" onClick={() => removeFarmRow(index)}>
                                Remove Farm
                            </button>
                        </div>
                    ))}
                </div>
                {!isLoading ? <button type="submit" className="btn btn-primary">Submit</button> :
                <button type="submit" className="btn btn-primary">Please wait...</button>}
            </form>
            {formStatus && <div className="mt-3 alert alert-info">{formStatus}</div>}
        </div>
    );
};

export default FarmForm;

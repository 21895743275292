import { useState } from "react"
import { genders } from './../../constants/genders';
import apiClient from "../../utils/axios";
import { useNavigate } from "react-router-dom";
import axios from "axios";

const CreateProduction = () => {

  const [errors, setErrors] = useState({});

  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    member_type: "",
    member_number: "",
    name: "",
    id_number: "",
    gender: "",
    farm_id: ""
  })

  const handleChange = (e) => {

    const { name, value } = e.target;


    setFormData({
      ...formData,
      [name]: value
    });

  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log("form data", formData)

    // Object to hold validation errors
    let formErrors = {};

    // Validate each field
    if (!formData.name) formErrors.name = "Name is required.";
    if (!formData.member_type) formErrors.member_type = "Member Type is required.";
    if (!formData.member_number) formErrors.member_number = "Member Number is required.";
    if (!formData.id_number) formErrors.id_number = "ID Number is required.";
    if (!formData.gender) formErrors.gender = "Gender is required.";
    if (!formData.farm_id) formErrors.farm_id = "Farm ID is required.";

    // If there are errors, set them in state and do not submit
    if (Object.keys(formErrors).length > 0) {
      setErrors(formErrors);
      return;
    } else {
      // alert("passed")
      setErrors({}); // Clear any previous errors
      let data = formData
      console.log("data",data)
      const response = await apiClient.post(`v1/members`, data);
      // console.log("response", response.data)
      // if (response.status === 200) {
      //   navigate('/list-members')
      // }
    }

  }

  return <div className="col-8 ">

    <div className="d-flex align-items-center mb-4">
      {/* Additional content can be added here */}
    </div>

      <div className="mb-3">
        <label className="form-label" htmlFor="contact-name-field">Name</label>
        <input
          type="text"
          className={`form-control ${errors.name ? 'is-invalid' : ''}`}
          placeholder="Enter Name"
          id="contact-name-field"
          name="name"
          value={formData.name}
          onChange={handleChange}
        />
        {errors.name && <div className="invalid-feedback">{errors.name}</div>}
      </div>

    <div className="mb-3">
      <label className="form-label" htmlFor="member-type-field">Member Type</label>
      <select
        name="member_type"
        className={`form-select ${errors.member_type ? 'is-invalid' : ''}`}
        id="member-type-field"
        value={formData.member_type}
        onChange={handleChange}
      >
        <option value="">Select Member Type</option>
        <option value="cooperative">Cooperative</option>
        <option value="estate">Estate</option>
      </select>
      {errors.member_type && <div className="invalid-feedback">{errors.member_type}</div>}
    </div>

    <div className="mb-3">
      <label className="form-label" htmlFor="phone-number-field">Member Number</label>
      <input
        type="text"
        className={`form-control ${errors.member_number ? 'is-invalid' : ''}`}
        name="member_number"
        placeholder="Enter Member Number"
        id="phone-number-field"
        value={formData.member_number}
        onChange={handleChange}
      />
      {errors.member_number && <div className="invalid-feedback">{errors.member_number}</div>}
    </div>

    <div className="mb-3">
      <label className="form-label" htmlFor="id-number-field">ID Number</label>
      <input
        type="number"
        className={`form-control ${errors.id_number ? 'is-invalid' : ''}`}
        placeholder="Enter ID Number"
        id="id-number-field"
        name="id_number"
        value={formData.id_number}
        onChange={handleChange}
      />
      {errors.id_number && <div className="invalid-feedback">{errors.id_number}</div>}
    </div>

    <div className="mb-3">
      <label className="form-label" htmlFor="gender-field">Gender</label>
      <select
        name="gender"
        className={`form-select ${errors.gender ? 'is-invalid' : ''}`}
        id="gender-field"
        value={formData.gender}
        onChange={handleChange}
      >
        <option value="">Select Gender</option>
        <option value="male">Male</option>
        <option value="female">Female</option>
      </select>
      {errors.gender && <div className="invalid-feedback">{errors.gender}</div>}
    </div>

    <div className="mb-3">
      <label className="form-label" htmlFor="farm-id-field">Farm ID</label>
      <input
        type="text"
        className={`form-control ${errors.farm_id ? 'is-invalid' : ''}`}
        placeholder="Enter Farm ID"
        id="farm-id-field"
        name="farm_id"
        value={formData.farm_id}
        onChange={handleChange}
      />
      {errors.farm_id && <div className="invalid-feedback">{errors.farm_id}</div>}
    </div>

    <div className="d-flex justify-content-end">
      <button
        type="submit"
        className="btn btn-primary"
        id="add-btn"
        onClick={handleSubmit}
      >
        + Add Member
      </button>
    </div>

  </div>
}

export default CreateProduction;

import {useNavigate} from 'react-router-dom'

const AllReports = () => {

    const navigate = useNavigate()
    const goTo = (url) =>{
        navigate(url)
    }
    return <>
      
         <div className="row g-gs">
         <div className="col-xxl-3 col-sm-6 mb-1">
                <div className="card" style={{ cursor: 'pointer' }} onClick={() => goTo('/reports/all-reports/map-generation-report')}>
                    <div className="nk-ecwg nk-ecwg6">
                        <div className="card-inner">
                            <div className="card-title-group">
                                <div className="card-title">
                                    <h6 className="title mt-2 text-center">Map Generation Report</h6>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="col-xxl-3 col-sm-6 ">
                <div className="card" style={{ cursor: 'pointer' }} onClick={() => goTo('/reports/all-reports/data-analytics-report')}>
                    <div className="nk-ecwg nk-ecwg6">
                        <div className="card-inner">
                            <div className="card-title-group">
                                <div className="card-title">
                                    <h6 className="title text-center mt-2"> Analytics  Report</h6>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <div className="col-xxl-3 col-sm-6 ">
                <div className="card" style={{ cursor: 'pointer' }} onClick={() => goTo('/reports/all-reports/data-collection-report')}>
                    <div className="nk-ecwg nk-ecwg6">
                        <div className="card-inner">
                            <div className="card-title-group">
                                <div className="card-title">
                                    <h6 className="title text-center mt-2">Data Collection Report</h6>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="col-xxl-3 col-sm-6 ">
                <div className="card" style={{ cursor: 'pointer' }} onClick={() => goTo('/reports/all-reports/mapping-report')}>
                    <div className="nk-ecwg nk-ecwg6">
                        <div className="card-inner">
                            <div className="card-title-group">
                                <div className="card-title">
                                    <h6 className="title text-center mt-2"> Mapping Report</h6>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            


           
        </div>

                

               

  
    </>
}

export default AllReports;
import { useEffect, useState } from "react";
import apiClient from "../../utils/axios";
import MapGenerator from "../../components/reports/MapGenerator";


const GisReport = () =>{

    const [isLoading,setIsLoading] = useState(true)
    const [error, setError] = useState("")
    const [totalCount, setTotalCount] = useState(0)
    const [cooperatives, setCooperatives] = useState([])
    const [hasData, setHasData] = useState(false)
    const[coopData,setCoopData] = useState(null)

 
    const generateMapHandler = async (event) => {
        const selectedId = event.target.value;
        // This will log the selected cooperative's ID
        if (selectedId) {
            const response = await apiClient.get(`v1/reports/map/${selectedId}`)
            console.log("response", response.data)
            setCoopData(response.data)
            setHasData(true)
        } else {
            setHasData(true)
        }
    };

    const getData = async () => {

        let url = `v1/cooperatives`

        try {
            const response = await apiClient.get(url)
            console.log("re",response.data)
            if(response.status === 200 ){
                setIsLoading(false)
                setCooperatives(response.data)
            }
        } catch (error) {
            setError("error",error.response)
        }
       
    }
   
    useEffect(()=>{
        getData()
    },[])

let data = <p>Test Gis report</p>;

if(isLoading){
    data = <p>Please wait...</p>
}

if(!isLoading && cooperatives.length === 0){
    data = <p>No data</p>
}
if (!isLoading && cooperatives) {
    data = (
        <select className="form-select" onChange={generateMapHandler}>
            <option value="">Select cooperative</option>
            {cooperatives.map((cooperative) => (
                <option key={cooperative.id} value={cooperative.id}>
                    {cooperative.name}
                </option>
            ))}
        </select>
    );
}

    return <>
    <h4>Select the Cooperative to get Map Generation Report</h4>
    
        <div>{data}</div>
        {hasData && <MapGenerator jsonData={coopData} />}

    </>
}
export default GisReport;
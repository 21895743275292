const AllSmsMessages = ({smsesList}) =>{
    console.log("ok")

    if(smsesList.length === 0 ){
        return <p>No sms sent yet</p>
    } 
   
    const noSent =(recipients) =>{
        return recipients.length
    }

    
    return <div className="table-responsive">
        <table className="table">
            <thead>
                <tr>
                    <td>
                        Message
                    </td>
                    <td>
                        Received By
                    </td>
                    <td>status</td>
                    <td>Farmer Type</td>
                </tr>
            </thead>
            <tbody>
            { smsesList.map(sms=>(
                <tr>
                    <td>
                        {sms.message}
                    </td>
                    <td>{noSent(sms.recipients)}</td>
                   
                    <td>{sms.status}</td>
                    <td>{sms.farm_type_id}</td>
                </tr>
            ))}

            </tbody>
        </table>
    </div>
}
export default AllSmsMessages;
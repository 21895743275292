import { useEffect, useState } from "react";
import {  Navigate, NavLink, useNavigate } from "react-router-dom";
import './main.css'
import Cookies from 'js-cookie';
import { roles as rolesData } from "../constants/roles";

const Sidebar = () => {

    const [activeItem, setActiveItem] = useState(null);
     const [roles, setRoles] = useState([]);

    const navigate = useNavigate();

    const hasRole = (roleName) => {
        let roleFound = roles.find(role => role.role === roleName)
        if (roleFound) {
            return true
        }
        return false;
    }

    useEffect(() => {
        const userRoles = Cookies.get('roles') ? JSON.parse(Cookies.get('roles')) : [];
        console.log("roless",userRoles  )
        setRoles(userRoles); 
    },[])




    const handleItemClick = (itemName) => {
        setActiveItem(itemName === activeItem ? null : itemName);
        if (itemName  === 'add-members') {
            navigate('/members/add-member');
        }
        if (itemName  === 'list-members') {
            navigate('/members/list-members');
        }
        if (itemName  === 'list-productions') {
            navigate('/productions/list-productions');
        }
        if (itemName === 'add-production') {
             navigate('/productions/add-production');
        }
        if(itemName === 'all-reports'){
            navigate('/reports/all-reports');
        }

        if (itemName === 'dashboard') {
            navigate('/dashboard')
        }
        if (itemName === 'estate-farmers') {
            navigate('/estate-farmers')
        }
        if (itemName === 'create-message') {
            navigate('/messaging/messages/create')
        }
        if(itemName === 'all-messages'){
            navigate('messaging/messages/sms-messages')
        }
       
    };

     
        
    return  <div class="navbar-vertical navbar nav-dashboard" style={{ marginLeft:'0px' }}>
            <div class="h-100" data-simplebar>
               
                <a href="#" class="navbar-brand" style={{color:'#072b4c'}}>Coffee Farmers Database</a>
            <ul class="navbar-nav flex-column" id="sideNavbar">
                
                <li class="nav-item" onClick={() => handleItemClick('dashboard')} style={{ cursor:'pointer' }}>
                    <a class={`nav-link has-arrow ${activeItem === 'dashboard' ? 'active' : ''}`} >
                        <i data-feather="home" class="nav-icon  icon-xxs"></i>
                        Dashboard
                    </a>
                </li>
                
              
               
                
                <li class="nav-item" onClick={() => handleItemClick('estate-farmers')} style={{ cursor:'pointer' }}>
                    <a class={`nav-link has-arrow ${activeItem === 'estate-farmers' ? 'active' : ''}`} >
                        <i data-feather="home" class="nav-icon  icon-xxs"></i>
                        Estate Farmers
                    </a>
                </li>

                 <li class="nav-item">
				<a
					class="nav-link has-arrow  collapsed "
					href="#!"
					data-bs-toggle="collapse"
					data-bs-target="#navKanban"
					aria-expanded="false"
					aria-controls="navKanban"
				>
					<i data-feather="layout" class="nav-icon me-2 icon-xxs"></i>
					Members
				</a>

				<div id="navKanban" class="collapse " data-bs-parent="#sideNavbar">
                        <ul class="nav flex-column">
                            <li class="nav-item" onClick={() => handleItemClick('list-members')}>
							<a class={`nav-link has-arrow ${activeItem === 'list-members' ? 'active' : ''}`}  href="#" >All Members</a>
                            </li>
                            
						<li class="nav-item" onClick={() => handleItemClick('add-members')}>
							<a class={`nav-link has-arrow ${activeItem === 'add-members' ? 'active' : ''}`} href="#" >Add Members</a>
						</li>
						
					</ul>
				</div>
                </li>

            
              
              
                <li class="nav-item">
                    <a
                    class="nav-link has-arrow  collapsed "
                    href="#!"
                    data-bs-toggle="collapse"
                    data-bs-target="#navecommerce"
                    aria-expanded="false"
                    aria-controls="navecommerce"
                    >
                    <i data-feather="shopping-cart" class="nav-icon me-2 icon-xxs"></i>
                    Production
                    </a>

                    <div id="navecommerce" class="collapse " data-bs-parent="#sideNavbar">
                    <ul class="nav flex-column" style={{ cursor:'pointer' }}>
                        <li class="nav-item" onClick={() => handleItemClick('list-productions')}>
                            <a class={`nav-link has-arrow ${activeItem === 'list-productions' ? 'active' : ''}`} >All Productions</a>
                        </li>
                            
                        <li class="nav-item" onClick={() => handleItemClick('add-production')}>
                            <a class={`nav-link has-arrow ${activeItem === 'add-production' ? 'active' : ''}`} >Create Production</a>
                        </li>
                            

                       
                      
                    </ul>
                    </div>
                </li>

                {hasRole(rolesData[0].super_admin) && <li class="nav-item">
                    <a
                        class="nav-link has-arrow  collapsed "
                        href="#!"
                        data-bs-toggle="collapse"
                        data-bs-target="#navReport"
                        aria-expanded="false"
                        aria-controls="navReport"
                    >
                        <i data-feather="shopping-cart" class="nav-icon me-2 icon-xxs"></i>Reports</a>

                    <div id="navReport" class="collapse " data-bs-parent="#sideNavbar">
                        <ul class="nav flex-column" style={{ cursor: 'pointer' }}>
                            <li class="nav-item" onClick={() => handleItemClick('all-reports')}>
                                <a class={`nav-link has-arrow ${activeItem === 'all-reports' ? 'active' : ''}`} >All Reports</a>
                            </li>
                          
                      
                        </ul>
                    </div>
                </li>}

                {hasRole(rolesData[0].super_admin) && <li class="nav-item">
                    <a
                        class="nav-link has-arrow  collapsed "
                        href="#!"
                        data-bs-toggle="collapse"
                        data-bs-target="#navMessaging"
                        aria-expanded="false"
                        aria-controls="navMessaging"
                    >
                        <i data-feather="shopping-cart" class="nav-icon me-2 icon-xxs"></i>Messaging</a>

                    <div id="navMessaging" class="collapse " data-bs-parent="#sideNavbar">
                        <ul class="nav flex-column" style={{ cursor: 'pointer' }}>
                            <li class="nav-item" onClick={() => handleItemClick('all-messages')}>
                                <a class={`nav-link has-arrow ${activeItem === 'all-messages' ? 'active' : ''}`} >All Messages</a>
                            </li>
                            <li class="nav-item" onClick={() => handleItemClick('create-message')}>
                                <a class={`nav-link has-arrow ${activeItem === 'create-message' ? 'active' : ''}`} >Create Message</a>
                            </li>
                          
                      
                        </ul>
                    </div>
                </li>}

            </ul>
            
                    {/* <div class="card bg-light shadow-none text-center mx-4 my-8">
                        <div class="card-body py-6">
                            <img src="./assets/images/background/giftbox.png" alt="dash ui - admin dashboard template" />
                            <div class="mt-4">
                                <h5>Unlimited Access</h5>
                                <p class="fs-6 mb-4">Upgrade your plan from a Free trial, to select Business Plan. Start Now</p>
                                <a href="#" class="btn btn-secondary btn-sm">Upgrade Now</a>
                            </div>
                        </div>
                    </div> */}
            </div>
        </div>
    // return  <div class="nk-sidebar-main is-light">
    //             <div class="nk-sidebar-inner" data-simplebar>
    //                 <div class="nk-menu-content menu-active" data-content="navHospital">
    //                     <h5 class="title">Hospital Management</h5>
    //                     <ul class="nk-menu">
    //                         <li class="nk-menu-item">
    //                             <NavLink to="/" class="nk-menu-link">
    //                                 <span class="nk-menu-icon"><em class="icon ni ni-dashboard-fill"></em></span>
    //                                 <span class="nk-menu-text">Dashboard</span>
    //                             </NavLink>
    //                         </li>
                       

    //                         <li className={`nk-menu-item has-sub ${activeItem === 'patient' ? 'active' : ''}`}>
    //                             <a href="#" className="nk-menu-link nk-menu-toggle" onClick={() => handleItemClick('patient')}>
    //                                 <span className="nk-menu-icon"><em className="icon ni ni-users-fill"></em></span>
    //                                 <span className="nk-menu-text">Patient</span>
    //                             </a>
    //                             <ul className="nk-menu-sub">
    //                                 <li className="nk-menu-item">
    //                                     <NavLink to="/patients/add-patient" className="nk-menu-link">
    //                                         <span className="nk-menu-text">Add Patient</span>
    //                                     </NavLink>
    //                                 </li>
    //                                 <li className="nk-menu-item">
    //                                     <NavLink to="/patients" className="nk-menu-link">
    //                                         <span className="nk-menu-text"> Patients List</span>
    //                                     </NavLink>
    //                                 </li>
    //                             </ul>
    //                         </li>
    //                         <li className={`nk-menu-item has-sub ${activeItem === 'patientVisits' ? 'active' : ''}`}>
    //                             <a href="#" className="nk-menu-link nk-menu-toggle" onClick={() => handleItemClick('patientVisits')}>
    //                                 <span className="nk-menu-icon"><em className="icon ni ni-plus-medi-fill"></em></span>
    //                                 <span className="nk-menu-text">Patient Visits</span>
    //                             </a>
    //                             <ul className="nk-menu-sub">
    //                                 <li className="nk-menu-item">
    //                                     <NavLink to="/patient-visits" className="nk-menu-link">
    //                                         <span className="nk-menu-text">List Patient Visits</span>
    //                                     </NavLink>
    //                                 </li>
                               
    //                             </ul>
    //                         </li> 

    //                         {isAdmin() && <li className={`nk-menu-item has-sub ${activeItem === 'Inventory' ? 'active' : ''}`}>
    //                             <a href="#" className="nk-menu-link nk-menu-toggle" onClick={() => handleItemClick('Inventory')}>
    //                                 <span className="nk-menu-icon"><em className="icon ni ni-plus-medi-fill"></em></span>
    //                                 <span className="nk-menu-text">Inventory</span>
    //                             </a>
    //                             <ul className="nk-menu-sub">
    //                                 {isShowMenu('enable-suppliers-menu') && <li className="nk-menu-item">
    //                                     <NavLink to="/inventory/suppliers" className="nk-menu-link">
    //                                         <span className="nk-menu-text">Suppliers</span>
    //                                     </NavLink>
    //                         </li>}
    //                          <li className="nk-menu-item">
                                
    //                                     <NavLink to="/inventory/drugs" className="nk-menu-link">
    //                                         <span className="nk-menu-text">Drugs</span>
    //                                     </NavLink>
    //                                 </li>
    //                                 <li className="nk-menu-item">
    //                                     <NavLink to="/inventory/brands" className="nk-menu-link">
    //                                         <span className="nk-menu-text">Brands</span>
    //                                     </NavLink>
    //                                 </li>
                                   
    //                                 <li className="nk-menu-item">
    //                                     <NavLink to="/inventory/batches" className="nk-menu-link">
    //                                         <span className="nk-menu-text">Batches</span>
    //                                     </NavLink>
    //                                 </li>
    //                             </ul>
    //                         </li> }
                    
    //                         {isAdmin() &&<li  className={`nk-menu-item  ${activeItem === 'report' ? 'active' : ''}`}>
    //                                 <NavLink to="/reports" class="nk-menu-link">
    //                                     <span class="nk-menu-icon"><em class="icon ni ni-plus-medi-fill"></em></span>
    //                                     <span class="nk-menu-text" >Report</span>
    //                                 </NavLink>
    //                         </li>}
                        
    //                         {isAdmin() &&  <li className={`nk-menu-item mt-1 ${activeItem === 'Settings' ? 'active' : ''}`} >
    //                             <NavLink to="/system-setup" class="nk-menu-link">
    //                                 <span class="nk-menu-icon"><em class="icon ni ni-plus-medi-fill"></em></span>
    //                                 <span class="nk-menu-text" >SetUp</span>
    //                             </NavLink>
    //                         </li>}
                    

    //                     </ul>
    //                 </div>
    //             </div>
    //         </div>
}

export default Sidebar;


import ViewFarm from "../farms/ViewFarm";

const ViewEstate = ({ estate,farmDetails }) => {

    console.log("estate",estate)
    return <div className="col-md-6">
        <div className="mt-3">
            <h5 className="mb-3">Estate Name : {estate.name} </h5> 
            

            <ViewFarm farmDetails={farmDetails} headerTitle={ `EstateFarms`} />
        </div>
    </div>
}

export default ViewEstate;
import ReactPaginate from "react-paginate"

const DataCollection = ({data,limit,count,handlePage}) =>{
  
  console.log("members",data.total_farms)
  const pageHandler = (data) =>{
    let pageNo = data.selected + 1
    handlePage(pageNo)
  }


           {/* Members and Farms Section */}
           return <>
           <div className="container my-4">
              <div className="row mb-3">
                <div className="col-md-6 mb-2">
                  <div className="card text-white bg-primary">
                    <div className="card-body">
                      <h5 className="card-title">Total Farms : <span>{data.total_farms}</span></h5>
                      {/* <p className="card-text"></p> */}
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="card text-white bg-success">
                    <div className="card-body">
                      <h5 className="card-title">Farms with Coordinates <small>{data.farms_with_coordinates}</small></h5>

                    </div>
                  </div>
                </div>
              </div>
             

              <div className="row">
              {data.members.data.map((member, index) => (
                <div className="col-md-4" key={index}>
                  <div className="card mb-4">
                  <h5 className="m-2">{member.member_name} (<small>Member Number: {member.member_number}</small>)</h5>
                    <div className="table-responsive p-2">
                      <table className="table table-bordered">
                        <tr className="text-dark">
                          <td>Farm ID</td>
                          <td>Coordinates</td>
                          <td>Entered By</td>
                        </tr>
                      
                      {member.farms.map((farm, idx) => (
                        <tr key={idx}>
                          <td>{farm.farm_id}</td>
                          <td>{farm.gis_coordinates}</td>
                          <td>{farm.entered_by_user}</td>
                        </tr>

                      ))}
                      </table>
                    </div>
                  </div>
                </div>
              ))}
            </div>

            <div className="mt-3 mb-2">
                        <ReactPaginate
                            previousLabel={"previous"}
                            nextLabel={"next"}
                            breakLabel={"..."}
                            pageCount={(count / limit)}
                            marginPagesDisplayed={3}
                            pageRangeDisplayed={3}
                            onPageChange={pageHandler}
                            containerClassName={"pagination justify-content-center"}
                            pageClassName={"page-item"}
                            pageLinkClassName={"page-link"}
                            previousClassName={"page-item"}
                            previousLinkClassName={"page-link"}
                            nextClassName={"page-item"}
                            nextLinkClassName={"page-link"}
                            breakClassName={"page-item"}
                            breakLinkClassName={"page-link"}
                                    activeClassName={"active"}
                                /> 
            </div>
            </div>


            {/* </div> */}
            </>
}
export default DataCollection;
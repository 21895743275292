
const Button = (props) => {
    const { onClick, children } = props
    
    return (
        <button type="submit" onClick={onClick} class="btn btn-lg btn-primary btn-block mt-2">{children}</button>
        // 
    );
}

export default Button;
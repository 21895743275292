import { useEffect, useState } from "react";
import ShowMember from "../../components/members/ShowMember";
import { useParams } from "react-router-dom";
import apiClient from "../../utils/axios";

const MemberView = () => {

    const {id} = useParams()
    const [member, setMember] = useState(null)
    const [isLoading,setIsIsLoading] = useState(true)

    useEffect(()=>{
        const getData = async () =>{
            const response  =  await apiClient.get(`v1/members/${id}`)
            if (response.status === 200) {
                setIsIsLoading(false)
                setMember(response.data)
            }
        }
        if(id){
            getData()
        }
    },[id])

    let data = null;

    if (isLoading) {
        data = <p>Please Wait ..</p>
    }
    if (!isLoading && !member) {
        data = <p>This member is not found</p>
    }
    if (!isLoading && member) {
        data = <ShowMember member={member} />
    }
    
    return data 
}

export default MemberView;
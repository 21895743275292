import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import apiClient from './../../utils/axios';
import ViewEstate from "../estate/ViewEstate";
import ViewFarm from "../farms/ViewFarm";

const ShowMember = ({ member }) => {
    
    const navigate = useNavigate()
    const goBackHandler = () => {
        navigate('/members/list-members')
    }

    return <div>
        <div className="btn btn-primary" onClick={goBackHandler}>Back to Members</div>
            <div className="mt-4">
                <div className="card shadow">
                    <div className="card-body">
                    <h4 className="card-title">Member Profile</h4>
                    <div className="row">
                        <div className="col-md-6">
                        <h5>Personal Information</h5>
                        <ul className="list-group list-group-flush">
                            <li className="list-group-item"><strong>Member Number:</strong> {member.member_number}</li>
                            <li className="list-group-item"><strong>Name:</strong> {`${member?.first_name} ${member?.middle_name} ${member?.last_name}`}</li>
                                <li className="list-group-item"><strong>ID Number:</strong> {member.id_number}</li>
                                 <li className="list-group-item"><strong>Member Number:</strong> {member?.member_no}</li>
                            <li className="list-group-item"><strong>Gender:</strong> {member.gender}</li>
                            <li className="list-group-item"><strong>Phone:</strong> {member.phone}</li>
                                
                            <li className="list-group-item"><strong>Email:</strong> {member.email ? member.email : 'N/A'}</li>
                            <li className="list-group-item"><strong>Membership Status:</strong> {member.membership_status}</li>
                        </ul>
                        </div>
                        {member.farms.length > 0 &&  <div className="col-md-6"> <ViewFarm farmDetails={member.farms} /> </div> }
                    </div>
                    </div>
                </div>
            </div>
    </div>
}
export default ShowMember;
import React, { useState } from 'react';

function AnalyticsReport() {
    const [formData, setFormData] = useState({
        dateFrom: '',
        dateTo: '',
        factory: '',
        cooperative: '',
        region: '',
        ownershipStatus: '',
       
        reportType: '',
        aggregationLevel: ''
    });

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        // Submit formData to your backend API
        console.log('Submitting report query', formData);
    };

    return (
        <div className="container mt-4">
            <h2 className="mb-4"> Membership Report</h2>
            <form onSubmit={handleSubmit}>
                <div className="row mb-3">
                    <div className="col-md-6">
                        <label className="form-label">Date From</label>
                        <input 
                            type="date" 
                            className="form-control" 
                            name="dateFrom" 
                            value={formData.dateFrom} 
                            onChange={handleChange} 
                        />
                    </div>
                    <div className="col-md-6">
                        <label className="form-label">Date To</label>
                        <input 
                            type="date" 
                            className="form-control" 
                            name="dateTo" 
                            value={formData.dateTo} 
                            onChange={handleChange} 
                        />
                    </div>
                </div>

                <div className="row mb-3">
                    <div className="col-md-6">
                        <label className="form-label">Factory</label>
                        <select 
                            className="form-select" 
                            name="factory" 
                            value={formData.factory} 
                            onChange={handleChange}>
                            <option value="">Select Factory</option>
                            {/* Add your factory options here */}
                        </select>
                    </div>
                    <div className="col-md-6">
                        <label className="form-label">Cooperative</label>
                        <select 
                            className="form-select" 
                            name="cooperative" 
                            value={formData.cooperative} 
                            onChange={handleChange}>
                            <option value="">Select Cooperative</option>
                            {/* Add your cooperative options here */}
                        </select>
                    </div>
                </div>

                <div className="row mb-3">
                    <div className="col-md-6">
                        <label className="form-label">Region</label>
                        <select 
                            className="form-select" 
                            name="region" 
                            value={formData.region} 
                            onChange={handleChange}>
                            <option value="">Select Region</option>
                            {/* Add region options */}
                        </select>
                    </div>
                    <div className="col-md-6">
                        <label className="form-label">Ownership Status</label>
                        <select 
                            className="form-select" 
                            name="ownershipStatus" 
                            value={formData.ownershipStatus} 
                            onChange={handleChange}>
                            <option value="">Select Status</option>
                            <option value="owned">Owned</option>
                            <option value="leased">Leased</option>
                            {/* Add more options */}
                        </select>
                    </div>
                </div>

                

                

                <div className="row mb-4">
                    <div className="col-md-6">
                        <label className="form-label">Aggregation Level</label>
                        <select 
                            className="form-select" 
                            name="aggregationLevel" 
                            value={formData.aggregationLevel} 
                            onChange={handleChange}>
                            <option value="factory">By Factory</option>
                            <option value="cooperative">By Cooperative</option>
                            <option value="region">By Region</option>
                        </select>
                    </div>

                      <div className="col-md-6">
                        <label className="form-label">Report Type</label>
                        <select 
                            className="form-select" 
                            name="reportType" 
                            value={formData.reportType} 
                            onChange={handleChange}>
                            <option value="summary">Summary Report</option>
                            <option value="detailed">Detailed Report</option>
                            <option value="correlation">Correlation Analysis</option>
                            {/* Add more report types as needed */}
                        </select>
                    </div>
                </div>


                <button type="submit" className="btn btn-primary">Generate Report</button>
            </form>
        </div>
    );
}

export default AnalyticsReport;

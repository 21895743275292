import { useEffect } from 'react';
import { store } from './store/store';
import MainPage from './layouts/main';
import Login from './pages/auth/Login';

function App() {
 
  
  useEffect(() => {
    //updated store by dispatching an action
    store.dispatch({
      type: 'UPDATE_NAME',
      payload:"JackSon"
    })
     console.log("my store", store.getState())
  }, [])
  
  return  <MainPage/>
}

export default App;

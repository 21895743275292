import { useEffect, useState } from "react";
import apiClient from "../../utils/axios";
import AllSmsMessages from "../../components/messages/AllSmsMessages";

const Messaging = () => {

    const [originalSmsesList,setOriginalSmsesList] = useState([])
    const [smses,setSmses] = useState([])
    const [totalCount,setTotalCount] = useState(0)
    const [isLoading,setIsLoading] = useState(false)

    const [pageNo, setPageNo] = useState(1)
    const limit = 10


    


    const getSmsData = async () =>{

        setIsLoading(true)
        let url = `v1/get-sms?pageNo=${pageNo}&limit=${limit}`

        const response = await apiClient.get(url)
        if(response.status ===  200){
            setIsLoading(false)
            setTotalCount(response.data.totalCount)
            setSmses(response.data.data.data)
            setOriginalSmsesList(response.data.data.data)
        }


       
    }

    useEffect(()=>{

        getSmsData()

    },[pageNo]);

    if(isLoading ){
        return <p>Loading sms ..</p>
    } 

    return !isLoading && <AllSmsMessages smsesList={smses} />
}

export default Messaging;


import { useEffect, useState } from "react";
import apiClient from "../../utils/axios"
import DataCollection from "../../components/reports/DataCollection";

const DataCollectionReport = () =>{
    const [isLoading,setIsLoading] = useState(true);
    const [collection,setCollection] = useState(null);
    const [pageNo,setPageNo] = useState(1);
    const[totalCount,setTotalCount] = useState(0)
    const limit = 12

    const getData = async () =>{
        let data ={
            pageNo:pageNo,
            limit:limit
        }
        const response = await apiClient.post('v1/reports/gis',data);
        // console.log("responsesss",response.data.members.data)
        
        if(response.status === 200){
            console.log("response data paginated",response.data )
            setTotalCount(response.data.totalCount)
            setIsLoading(false)
            setCollection(response.data)
        }else{
            console.log("an error occured");
        }
    }

    const pageNoHandler = (number) =>{
        setPageNo(number)
    }


    useEffect(()=>{
        getData()
    },[pageNo])

   

    let data =null;

    if(isLoading ){
        data = <p>please wait...</p>
    }
    
    if(!isLoading && !collection){
        data =  <p>No Collection</p>
    } 
    if(!isLoading && collection ){
        data = <DataCollection data={collection} count={totalCount} handlePage={pageNoHandler} limit={limit}/>
    } 

    return data;
}

export default DataCollectionReport;
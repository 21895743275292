
import { React, useEffect, useState } from 'react';
import apiClient from '../../utils/axios';
import { useNavigate } from 'react-router-dom';
// import CreateMember from '../../components/members/CreateMember';


import CreateNewMember from '../../components/members/CreateNewMember';
import BulkUploadForm from '../../components/members/bulkupload/BulkUploadForm';

const AddMembers = () => {

  const navigate = useNavigate();
  const [varieties,setVarieties] = useState([])
  const [location, setLocation] = useState({ latitude: "", longitude: "" });
  
  const [cooperatives, setCooperatives] = useState([]);
  const [factories, setFactories] = useState([]);
  const [plantationTypes, setPlantationTypes] = useState([]);
  const[isShowBulkUploadForm,setIsShowBulkUploadForm] = useState(false)


    const handleSubmit = async () =>{
        let data = {

        }
        const response = await apiClient.post(data)
        if(response.status === 200){
            navigate("/list-members")
        }
    } 

    const getVarieties = async () =>{
        try {
            const response = await apiClient.get(`v1/varieties`)
            if( response.status === 200 ){
                setVarieties(response.data)
                // console.log("response",response.data)
            }
        } catch (error) {
            console.log("error",error)
        }
        
    }

    const getLocation = () => {
    
    if (navigator.geolocation) {
      console.log(navigator.geolocation)
      navigator.geolocation.getCurrentPosition(
        (position) => {
        //   console.log("latitude", position)
          //  console.log("latitude", position.coords.longitude)
          setLocation({
            latitude: position.coords.latitude,
            longitude: position.coords.longitude,
          });

        },
        (error) => {
          console.log(error.message);
        }
      );
    } else {
      console.log("Geolocation is not supported by this browser.");
    }
  };


    useEffect(()=>{
        const getData = async () =>{
            getVarieties()
        }
        getData()
    },[])

    useEffect(()=>{
        // getLocation()
    },[])

  
    useEffect(() => {
        // Fetch cooperatives and factories
        apiClient.get('/v1/cooperatives')
            .then(response => setCooperatives(response.data))
            .catch(error => console.error('Error fetching cooperatives:', error));

        apiClient.get('/v1/factories')
            .then(response => setFactories(response.data))
        .catch(error => console.error('Error fetching factories:', error));
      
        apiClient.get('/v1/crop-types')
              .then(response => setPlantationTypes(response.data))
          .catch(error => console.error('Error fetching crop types:', error));
        
        apiClient.get('/v1/varieties')
              .then(response => setVarieties(response.data))
          .catch(error => console.error('Error fetching varieties:', error));
      
      
      
    }, []);

  
  

    return <>
    {/* <CreateMember varieties={varieties} location={location}/> */}
    
    {!isShowBulkUploadForm && <div className="container mt-4">
      <div onClick={()=>setIsShowBulkUploadForm(true)} className='btn btn-primary'>Bulk Upload</div>
    </div>}
    {isShowBulkUploadForm && <BulkUploadForm closeBulkUploadForm={()=>setIsShowBulkUploadForm(false)}/>}
    {!isShowBulkUploadForm && <CreateNewMember
        varieties={varieties}
        location={location}
        cooperatives={cooperatives}
        factories={factories}
        plantationTypes={plantationTypes}
        
      />}
    </>
}

export default AddMembers;
import { useEffect, useState } from "react";
import apiClient from "../../utils/axios";

const ViewFarm = ({ farmDetails,headerTitle }) => {

     const [statuses, setStatuses] = useState([]);
    
     const selectesStatusHandler = async (farmId, e) => {
        const selectedStatus = e.target.value;
        let data = {
            farmId: farmId,  // Update with the passed farmId
            status: selectedStatus 
        }
        // console.log(data)
        const response = await apiClient.post(`v1/farm-update-ownership`, data)
        if (response.data.status) {
            console.log("Update successful:", response.data.message);
        } else {
            console.log("Update failed:", response.data.message);
        }
        

    }


     const getData = async () => {
            const response = await apiClient.get('v1/ownership-status');
            if (response.status === 200) {
                setStatuses(response.data);
            }
        }

    useEffect(() => {
        
        getData();
    }, [])
    
    return <div >
                <h5>Farms Information {headerTitle && <p>({headerTitle})</p> }</h5>
                <div className="table-responsive">
                    <table className="table table-bordered table-hover">
                        <thead className="thead-light">
                    <tr>
                            <th>Farm</th>
                            <th>GIS Coordinates</th>
                            <th>Land Near Forest</th>
                            <th>Land Near Shopping Centre</th>
                            <th>Land Size</th>
                            <th>Farm Ownership </th>
                        </tr>
                        </thead>
                        <tbody>
                            {farmDetails.map((farm, index) => (
                                <tr key={index}>
                                <td>Farm { index + 1}</td>
                                <td>
                                    <a
                                        href={`https://www.google.com/maps?q=${farm.gis_coordinates}`}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                    {farm.gis_coordinates}
                                    </a>
                                </td>
                                <td>{farm.land_near_forest ? 'Yes' : 'No'}</td>
                                <td>{farm.land_near_shopping_centre ? 'Yes' : 'No'}</td>
                                    <td>{farm.land_size} Acres</td>

                                    <td>
                                        {farm.ownershipStatus === null && statuses && statuses.length > 0 ?
                                            <select onChange={(e) => selectesStatusHandler(farm.id, e)} className="form-select"> 
                                            <option className="" >select ownership</option>
                                            {statuses.map(status => (
                                                <option key={status.id} value={status.id}>{status.name }</option>
                                            ))}
                                        </select>
                                    : farm?.ownershipStatus?.name} 
                                        
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
}

export default ViewFarm;
import { useEffect, useState } from "react";
import ReactPaginate from "react-paginate";
import apiClient from "../../utils/axios";
import ViewEstate from "./ViewEstate";
import ViewFarm from "../farms/ViewFarm";

const ListEstates = ({ estates, count, pageNoHandler ,hideSearch,reloadEstates}) => {
    
    const [showFarmDetails, setShowFarmDetails] = useState(false)
    const [farmDetails, setFarmDetails] = useState([])
    const [statuses, setStatuses] = useState([]);
    const [estate, setEstate] = useState(null);
    const[message,setMessage] = useState("")
    

    const handleView = (id) => {
        let estate = estates.find(estate => estate.id === id)
        if (estate.farms !== null) {
            setEstate(estate)
            setShowFarmDetails(true)
            setFarmDetails(estate.farms)
            hideSearch(false)
        } 
    }

    const handleDelete = async (estateId) => {
        // eslint-disable-next-line no-restricted-globals
        const confirmResponse = confirm("This will delete all the data related to this estate. Are you sure?");
        
        if (confirmResponse) {
            try {
                const response = await apiClient.delete(`v1/estate-farmers/${estateId}`);
                console.log("response delete",response.data.message)
                if (response.status === 200 && response.data.status) {
                    console.log("Estate deleted successfully.");
                    reloadEstates(); // Assuming this function reloads the list of estates.
                    setMessage(response.data.message)
                   
                } else {
                    console.log("Failed to delete the estate.");
                }
            } catch (error) {
                console.error("An error occurred while deleting the estate:", error);
            }
        }
    };
    

    const goBackToHandler = () => {
        setShowFarmDetails(false)
        hideSearch(true)
    }


     const getData = async () => {
            const response = await apiClient.get('v1/ownership-status');
            if (response.status === 200) {
                setStatuses(response.data);
            }
        }
    
       

    useEffect(() => {
        getData();
    },[])


   if(estates.length === 0 ){
        return <p>No Estates</p>
    }
    return <div className="table-responsive">
             <p className="text-center text-danger">{message}</p>
          
            <div className="card mb-4">

            <div className="card-body">

                {!showFarmDetails ? <div class="table table-responsive table-card">
                    <table className="table text-nowrap mb-0 table-centered">
                        <thead className="table-light">
                            <tr>
                                <th class="pe-0"></th>
                                <th>Name</th>
                                <th>Email</th>
                                <th>Number of Farms</th>
                                <th>Phone</th>
                            
                                <th>Action</th>
                            </tr>
                        </thead>

                            {estates.map(estate => (<tr key={estate.id}>
                                <td className="pe-0"></td>
                                <td className="ps-0"><b>{estate.name}</b></td>
                                <td className="ps-0"><b>{estate.email}</b></td>
                                <td className="ps-0">{estate.no_of_firms}</td>
                                <td className="ps-0">{estate.phone}</td>
                            
                                <td>
                                    <div>
                                        <div className="dropdown">
                                            <a className="btn btn-ghost btn-icon btn-sm rounded-circle" href="#!" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="feather feather-more-vertical icon-xs"><circle cx="12" cy="12" r="1"></circle><circle cx="12" cy="5" r="1"></circle><circle cx="12" cy="19" r="1"></circle></svg>
                                            </a>

                                            <div className="dropdown-menu">

                                                <a className="dropdown-item d-flex align-items-center" href="#!" onClick={() => handleView(estate.id)} >
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="feather feather-eye me-2 icon-xs"><path d="M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z"></path><circle cx="12" cy="12" r="3"></circle></svg>View
                                                </a>

                                                <a className="dropdown-item d-flex align-items-center" href="#!" onClick={() => handleDelete(estate.id)} >
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="feather feather-eye me-2 icon-xs"><path d="M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z"></path><circle cx="12" cy="12" r="3"></circle></svg><span class="text-danger">Delete</span>
                                                </a>

                                            </div>
                                        </div>
                                    </div>
                                </td>
                            </tr>))}
                    </table>

                    <div className="mt-3 mb-2">
                        <ReactPaginate
                            previousLabel={"previous"}
                            nextLabel={"next"}
                            breakLabel={"..."}
                            pageCount={(count / 10)}
                            marginPagesDisplayed={3}
                            pageRangeDisplayed={3}
                            onPageChange={pageNoHandler}
                            containerClassName={"pagination justify-content-center"}
                            pageClassName={"page-item"}
                            pageLinkClassName={"page-link"}
                            previousClassName={"page-item"}
                            previousLinkClassName={"page-link"}
                            nextClassName={"page-item"}
                            nextLinkClassName={"page-link"}
                            breakClassName={"page-item"}
                            breakLinkClassName={"page-link"}
                            activeClassName={"active"}
                        />
                    </div>
                </div> : <>
                            <div className="btn btn-primary" onClick={goBackToHandler}> Go Back</div>
                            {farmDetails.length > 0 && <ViewEstate estate={ estate}  farmDetails={farmDetails} />}
                        </>
                 }
            </div>

        </div>
        
    </div>
}



export default ListEstates;
import {  HashRouter  as Router, Route, Routes, useNavigate } from "react-router-dom";

// import Login from "../pages/auth/Login";

// import  useCheckRole  from "../reusables/CheckRoleOrPermission";
import AddMembers from './../pages/members/AddMembers';
import ListMembers from "../pages/members/ListMembers";
import Dashboard from "../pages/Dashboard";
import EditMember from "../pages/members/EditMember";
import ViewMember from "../pages/members/ViewMember";
import ListProductions from "../pages/productions/ListProductions";
import AddProductions from "../pages/productions/AddProductionss";
import { useAuth } from "../store/AuthContext";
import MemberView from "../pages/members/MemberView";
// import AllReports from "../pages/reports/AllReports";
import DataCollectionReport from "../pages/reports/DataCollectionReport";
import GisReport from "../pages/reports/GisReport";
import AllReports from "../pages/reports/AllReports";
import AnalyticsReport from "../pages/reports/AnalyticsReport";
import AllEstateFarmers from "../pages/estate/AllEstateFarmers";
import CreateFarmEstate from "../pages/estate/CreateFarmEstate";
import Messaging from "../pages/messaging/Messaging";
import CreateMessage from "../pages/messaging/CreateMessage";
import ProtectedRoute from "./ProtectedRoute";

import { roles } from "../constants/roles";
import MappingReport from "../pages/reports/MappingReport";


const NavPage = () => {

    const {isLoggedIn} = useAuth()
   
    return (
        <Routes>

           

             {/* <Route path="/page-does-not-exist" element={<UnauthorizedPage />} /> */}
            
            <Route 
                path="/dashboard" 
                element={
                    <ProtectedRoute isLoggedIn={isLoggedIn} > 
                        <Dashboard />
                    </ProtectedRoute>
                }
            />

             <Route 
                path="/members/add-member" 
                element={
                    <ProtectedRoute isLoggedIn={isLoggedIn}>
                        <AddMembers />
                    </ProtectedRoute>
                }
            />

            <Route 
                path="/members/list-members" 
                element={
                    <ProtectedRoute isLoggedIn={isLoggedIn}>
                        <ListMembers />
                    </ProtectedRoute>
                }
            />

             <Route 
                path="/members/edit/:id" 
                element={
                    <ProtectedRoute isLoggedIn={isLoggedIn}>
                        <EditMember />
                    </ProtectedRoute>
                }
            />

             <Route 
                path="/members/view/:id" 
                element={
                    <ProtectedRoute isLoggedIn={isLoggedIn}>
                        <MemberView />
                    </ProtectedRoute>
                }
            />

             <Route 
                path="/productions/list-productions" 
                element={
                    <ProtectedRoute isLoggedIn={isLoggedIn}>
                        <ListProductions />
                    </ProtectedRoute>
                }
            />

            <Route 
                path="/productions/add-production" 
                element={
                    <ProtectedRoute isLoggedIn={isLoggedIn}>
                        <AddProductions />
                    </ProtectedRoute>
                }
            />

             <Route 
                path="/reports/all-reports" 
                element={
                    <ProtectedRoute isLoggedIn={isLoggedIn}>
                        <AllReports />
                    </ProtectedRoute>
                }
            />

            <Route 
                path="/reports/all-reports/data-collection-report" 
                element={
                    <ProtectedRoute isLoggedIn={isLoggedIn}>
                        <DataCollectionReport />
                    </ProtectedRoute>
                }
            />

            <Route 
                path="/reports/all-reports/map-generation-report" 
                element={
                    <ProtectedRoute isLoggedIn={isLoggedIn}>
                        <GisReport />
                    </ProtectedRoute>
                }
            />

             <Route 
                path="/reports/all-reports/data-analytics-report" 
                element={
                    <ProtectedRoute isLoggedIn={isLoggedIn}>
                        <AnalyticsReport />
                    </ProtectedRoute>
                }
            />

            <Route 
                path="/reports/all-reports/mapping-report" 
                element={
                    <ProtectedRoute isLoggedIn={isLoggedIn}>
                        <MappingReport />
                    </ProtectedRoute>
                }
            />



            <Route 
                path="/estate-farmers" 
                element={
                    <ProtectedRoute isLoggedIn={isLoggedIn}>
                        <AllEstateFarmers />
                    </ProtectedRoute>
                }
            />

            <Route 
                path="/estate-farmers/add-estate" 
                element={
                    <ProtectedRoute isLoggedIn={isLoggedIn}>
                        <CreateFarmEstate />
                    </ProtectedRoute>
                }
            />

            <Route 
                path="/messaging/messages/sms-messages" 
                element={
                    <ProtectedRoute isLoggedIn={isLoggedIn} >
                        <Messaging />
                    </ProtectedRoute>
                }
            />

            <Route 
                path="/messaging/messages/create" 
                element={
                    <ProtectedRoute isLoggedIn={isLoggedIn} >
                        <CreateMessage />
                    </ProtectedRoute>
                }
            />


            {/* {isLoggedIn && <Route path="/dashboard" element={<Dashboard />} />}
            {isLoggedIn && <Route path="/members/add-member" element={<AddMembers />} />}
            {isLoggedIn && <Route path="/members/list-members" element={<ListMembers />} />}
            {isLoggedIn && <Route path="/members/edit/:id" element={<EditMember />} />}
            {isLoggedIn && <Route path="/members/view/:id" element={<MemberView />} />}
            {isLoggedIn && <Route path="/productions/list-productions" element={<ListProductions />} />}
            {isLoggedIn && <Route path="/productions/add-production" element={<AddProductions />} />}  */}
            {/* {isLoggedIn && <Route path="/reports/all-reports" element={<AllReports />} />} 
            {isLoggedIn && <Route path="/reports/all-reports/data-collection-report" element={<DataCollectionReport />} />}
            {isLoggedIn && <Route path="/reports/all-reports/map-generation-report" element={<GisReport />} />} 
            {isLoggedIn && <Route path="/reports/all-reports/data-analytics-report" element={<AnalyticsReport />} />} 
            {isLoggedIn && <Route path="/estate-farmers" element={<AllEstateFarmers />} />}
            {isLoggedIn && <Route path="/estate-farmers/add-estate" element={<CreateFarmEstate />} />}
            {isLoggedIn && <Route path="/messaging/messages" element={<Messaging />} />}
            {isLoggedIn && <Route path="/messaging/messages/create" element={<CreateMessage />} />} */}
            
            
            
        </Routes>
    );
}

export default NavPage;



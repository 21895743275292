
import React, { useEffect, useState } from 'react';
import apiClient from '../../utils/axios';
import { useNavigate } from 'react-router-dom';

const CreateFarmEstate = () => {
    const [counties, setCounties] = useState([]);
    const [estateName, setEstateName] = useState('');
    const [estateEmail, setEstateEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [farmDetails, setFarmDetails] = useState([]);
    const [message, setMessage] = useState("")
    const [isSaved, setIsSaved] = useState(false)
    const[isSubmitting,setIsSubmitting] = useState(false)
    const [isEmpty, setIsEmpty] = useState(false)
  
  const navigate = useNavigate()

  // Handle adding a new farm detail entry
  const handleAddFarm = () => {
    setFarmDetails([
      ...farmDetails,
      {
        ward:'',
        landSize: '',
        coffeeBushes: '',
        nearForest: false,
        nearShoppingCenter: false,
        subCounty: '',
      },
    ]);
  };



                              

  // Handle removing a farm detail entry
  const handleRemoveFarm = (index) => {
    const updatedFarmDetails = farmDetails.filter((_, i) => i !== index);
    setFarmDetails(updatedFarmDetails);
  };

  // Handle change for each farm's details
  const handleFarmDetailChange = (index, field, value) => {
    const updatedFarmDetails = [...farmDetails];
    updatedFarmDetails[index][field] = value;
    setFarmDetails(updatedFarmDetails);
  };




  const validateForm = (formData) => {
    // Check if any of the string fields are empty
    if (
        !formData.estateName.trim() || 
        !formData.estateEmail.trim() || 
        !formData.phone.trim() || 
        formData.farmDetails.length === 0
    ) {
        return false; // Form is invalid
    }
    return true; // Form is valid
  };
  



  

  const handleSubmit = async (e) => {
    e.preventDefault();

      const formData = {
          estateName: estateName,
          estateEmail: estateEmail,
          phone: phone,
          farmDetails: farmDetails // Assuming these are the values from your form state
      };

      // Validate form
      if (validateForm(formData)) {
          setIsSubmitting(true)
          // Submit form data (you can use an API call here)
          let data = { estateName,estateEmail,phone, farmDetails }
          try {
            const response = await apiClient.post('v1/estate-farmers', data);
            
            if (response.status === 201 && response.data.status) {
                setIsSubmitting(false)
                setMessage(response.data.message);
                setIsSaved(response.data.status)
                setIsEmpty(false)
                navigate('/estate-farmers')
            } 
          } catch (error) {
               console.log("eeror",error)
              setIsSubmitting(false)
              setMessage(error.response.data.message);
              setIsSaved(error.response.data.status)
              setIsEmpty(false)
          }
      } else {
          setIsEmpty(true)
          // Show an error or prevent submission
          console.log("Form is incomplete, please fill in all required fields.");
      }
      
    };
    
    const getCountiesData = async () => {
      const response = await apiClient.get('v1/counties');
      console.log(response.data)
        if (response.status === 200) {
             setCounties(response.data)
        }
       
    }

    useEffect(() => {
        getCountiesData()
    },[])
  return (
    <form onSubmit={handleSubmit}>
      <div className="row mb-3">
        <div className="col-md-3">
          <div className="form-group">
            <label>Estate Name</label>
            <input
              type="text"
              className="form-control"
              value={estateName}
              onChange={(e) => setEstateName(e.target.value)}
              placeholder="Enter Estate Name"
            />
          </div>
        </div>

         <div className="col-md-3">
          <div className="form-group">
            <label>Estate Email</label>
            <input
              type="email"
              className="form-control"
              value={estateEmail}
              onChange={(e) => setEstateEmail(e.target.value)}
              placeholder="Enter Estate Email"
            />
          </div>
        </div>

        <div className="col-md-3">
          <div className="form-group">
            <label>Estate Phone</label>
            <input
              type="text"
              className="form-control"
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
              placeholder="Enter Estate Phone"
            />
          </div>
        </div>

      </div>

      {/* Dynamically render farm details */}
      {farmDetails.map((farm, index) => (
        <div key={index} className="border p-3 mb-3">
            <div className="d-flex justify-content-between">
                <h5>Farm {index + 1}</h5>
                <button
                type="button"
                className="btn btn-danger"
                onClick={() => handleRemoveFarm(index)}
                >
                Remove Farm
                </button>
            </div>
            <div className="row mb-3">
                <div className="col-md-3">
                <div className="form-group">
                    <label>Land Size</label>
                    <input
                    type="number"
                    className="form-control"
                    value={farm.landSize}
                    onChange={(e) =>
                        handleFarmDetailChange(index, 'landSize', e.target.value)
                    }
                    placeholder="Enter Land Size"
                    />
                </div>
                </div>

              <div className="col-md-3">
                <div className="form-group">
                    <label>Number of Coffee Bushes</label>
                    <input
                    type="number"
                    className="form-control"
                    value={farm.coffeeBushes}
                    onChange={(e) =>
                        handleFarmDetailChange(index, 'coffeeBushes', e.target.value)
                    }
                    placeholder="Enter Number of Coffee Bushes"
                    />
                </div>
            </div>
            
             <div className="col-md-3">
                <div className="form-group">
                    <label>Ward</label>
                      <input
                      type="text"
                      className="form-control"
                      value={farm.ward}
                      onChange={(e) =>
                          handleFarmDetailChange(index, 'ward', e.target.value)
                      }
                      placeholder="Enter Ward"
                      />
                  </div>
                </div>
            
            
            
                <div className="col-md-3">
              <div className="form-group">
                
                 <label>Sub County</label>
                      <input
                      type="text"
                      className="form-control"
                      value={farm.subCounty}
                      onChange={(e) =>
                          handleFarmDetailChange(index, 'subCounty', e.target.value)
                      }
                      placeholder="Enter Sub County"
                />
                

                    {/* <label>Sub County</label>
                    <select
                    className="form-control"
                    value={farm.subCounty}
                    onChange={(e) =>
                        handleFarmDetailChange(index, 'subCounty', e.target.value)
                    }
                    >
                    <option value="">Select SubCouty</option>
                    <option value="County A">County A</option>
                    <option value="County B">County B</option>
                    <option value="County C">County C</option>
                    </select> */}
                </div>
                </div>

            </div>
              
            <div className="row">
                <div className="form-group d-flex gap-1">
                    <label>Near Forest</label>
                    <input
                    type="checkbox"
                    className="form-check-input"
                    checked={farm.nearForest}
                    onChange={(e) =>
                        handleFarmDetailChange(index, 'nearForest', e.target.checked)
                    }
                    />{' '}
                    {/* Is the land near a forest? */}
                </div>
                    
                <div className="form-group d-flex gap-1">
                    <label>Near Shopping Center </label>
                    <input
                    type="checkbox"
                    className="form-check-input"
                    checked={farm.nearShoppingCenter}
                    onChange={(e) =>
                        handleFarmDetailChange(index, 'nearShoppingCenter', e.target.checked)
                    }
                    />{' '}
                    {/* Is the land near a shopping center? */}
                </div>
                  
            </div>
        </div>
      ))}

      <div className="mb-3">
        <button
          type="button"
          className="btn btn-secondary"
          onClick={handleAddFarm}
        >
          Add Farm
        </button>
      </div>

      {!isSubmitting && <button type="submit" className="btn btn-primary"> Submit </button>}
      {isSubmitting && <button className="btn btn-primary"> Please Wait... </button>}
      {isSaved && <p className='text-success fw-bold mt-2'>{message}</p>}
      {!isSaved && <p className='text-danger fw-bold mt-2'>{message}</p>}
      {isEmpty && <p className='text-danger fw-bold mt-2'>Form is incomplete, please fill in all required fields.</p>}
    </form>
  );
};

export default CreateFarmEstate;

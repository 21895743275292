import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import apiClient from "../../utils/axios";
import EditSingleMember from "../../components/members/EditSingleMember";

const EditMember = () =>{
    const {id} = useParams()
    const [member,setMember] = useState(null)
    const [varieties, setVarieties] = useState([])
     const [isLoading,setIsIsLoading] = useState(true)



    const getVarieties = async () =>{
        try {
            const response = await apiClient.get(`v1/varieties`)
            if (response.status === 200) {
                setIsIsLoading(false)
                setVarieties(response.data)
                console.log("response",response.data)
            }
        } catch (error) {
            console.log("error",error)
        }
        
    }

    const loadMemberHandler = () => {
        getData();
    }

     const getData = async () =>{
            const response  =  await apiClient.get(`v1/members/${id}`)
            console.log("response",response.data)
            if(response.status === 200){
                setMember(response.data)
            }
        }



    useEffect(()=>{
       
        if(id){
            getData()
        }
    },[id])

    useEffect(()=>{
        const getData = async () =>{
            getVarieties()
        }
        getData()
    },[])

    let data = null;
    if (isLoading) {
        data = <p>Please wait...</p>
    }
    if (!isLoading && !member) {
         data = <p>This member is not found</p>
    }
    if (!isLoading && member) {
        data = <EditSingleMember memberData={member} varieties={varieties} loadMember={loadMemberHandler } />
    }

    return data;
}
export default EditMember;
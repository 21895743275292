import { useState } from "react";
import Login from "../pages/auth/Login";
import { useAuth } from "../store/AuthContext";
import Header from "./header";
import NavPage from "./NavPage";
import Sidebar from "./sidebar";
import './main.css'
import { isVisible } from "@testing-library/user-event/dist/utils";

const MainPage = () => {

  const {isLoggedIn} = useAuth()
  const [isSidebarVisible, setSidebarVisible] = useState(true);
  const toggleSidebar = () => {
    setSidebarVisible(!isSidebarVisible);
  };


  
      const MainEntry =  <main id="main-wrapper"   className={`main-wrapper ${isSidebarVisible ? '' : 'sidebar-hidden'}`}>
            {/* <div className="header"> */}
                <Header onToggleSidebar={toggleSidebar} isSidebarVisible={isSidebarVisible}/>
            {/* </div> */}

            <div className={`app-menu ${isSidebarVisible ? '' : 'hidden'}`}>
                <Sidebar  />
            </div>

            <div className= {`app-content ${isSidebarVisible ? '' : 'app-content-expand'}`}>
            {/* <div id="app-content"> */}
                <div className="app-content-area">
                    <div className="pt-10 pb-21 mt-n6 mx-n4"></div>
                    <div className="container-fluid mt-n22">
                        <NavPage />
                    </div>
                </div>
            </div>
        </main>


        const LoginForm = <Login/>

        return !isLoggedIn ? LoginForm : MainEntry
    
}

export default MainPage;


import { React, useEffect, useState } from 'react';
import apiClient from '../../utils/axios';
import AllMembers from '../../components/members/AllMembers';
import { NavLink } from 'react-router-dom';

const ListMembers = () => {

    const [pageNo, setPageNo] = useState(1)
    const [limit,setLimit] = useState(10)
    const [isLoading,setIsLoading] = useState(true)
    const[members,setMembers] = useState([])
    const [originalList, setOriginalList] = useState();
    const[totalCount,setTotalCount] = useState(0)



    const getMembers = async () =>{
      
        let url = `v1/members?pageNo=${pageNo}&limit=${limit}`

        const response = await apiClient.get(url)
        if(response.status ===  200){
            setIsLoading(false)
            setTotalCount(response.data.totalCount)
            setMembers(response.data.data.data)
            setOriginalList(response.data.data.data)
        }

    }

    const pageNoHandler = (number) =>{
        setPageNo(number)
    }

   

    const handleSearch = async (e) => {
		 const value = e.target.value.trim();
		if (value.trim() === "") {
			setMembers(originalList)
        } else {
            let data = {
                search:value
            }

            try {
                const response = await apiClient.post('v1/members/search-member', data);
                
                if (response.status === 200) {
                    if (response.data.data.data.length > 0) {
                        setTotalCount(response.data.totalCount)
                        setMembers(response.data.data.data)
                    } else {
                        setMembers(originalList)
                    }
               }
                
            } catch (error) {
                console.log("error", error);
            }
             
            
			
		}
		
	}


    useEffect(()=>{
        const getData = async () =>{
            await getMembers()
        }
        getData()
    },[pageNo])
    

    return isLoading ? <p>Loading ...</p> : <>
        <div class="card-header">
            <h3>Members ({totalCount})</h3>
                    <div class="row g-2">
                        <div class="d-grid d-lg-block ">
                            <div className="d-flex justify-content-between">
                                <input type="search" placeholder="Search Member by id,first name, last name" onChange={(e) => handleSearch(e)} className="form-control" style={{ width: '280px' }} />
                                <NavLink to="/members/add-member" className="btn btn-primary">+ Create New Member</NavLink>
                            </div>
                            
                        </div>
                    </div>
                </div>
        <AllMembers members={members} count={totalCount} pageNoHandler={pageNoHandler}/>
    </>
}

export default ListMembers;
import { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import apiClient from "../../utils/axios";
import ListEstates from "../../components/estate/ListEstates";

const AllEstateFarmers = () => {

    const [pageNo, setPageNo] = useState(1)
    const [limit, setLimit] = useState(10)
    const [isLoading, setIsLoading] = useState(true)
    const [totalCount, setTotalCount] = useState(0)
    const [estates, setEstates] = useState([])
    const [originalList, setOriginalList] = useState([])
    const [isShowSearch, setIsShowSearch] = useState(true)
    

    const reloadEstatesHandler = () => {
        setPageNo(1)
        getEstatesData();
    }

    const getEstatesData = async () => {
       

        let url = `v1/estate-farmers?pageNo=${pageNo}&limit=${limit}`
        try {
            const response = await apiClient.get(url)

            if (response.status === 200) {
                setIsLoading(false)
                setTotalCount(response.data.totalCount)
                setEstates(response.data.data.data)
                setOriginalList(response.data.data.data)
            }
        } catch (error) {
            console.log(error)
        }
    }

    const pageNoHandler = (number) => {
        
        setPageNo(number.selected + 1)
    }

    const handleSearch = async (e) => {
        const value = e.target.value.trim();
        if (value.trim() === "") {
            setEstates(originalList)
        } else {
            let data = {
                search: value
            }

            try {
                const response = await apiClient.post('v1/estates/search-estate', data);
                
                if (response.status === 200) {
                    if (response.data.data.data.length > 0) {
                        setTotalCount(response.data.totalCount)
                        setEstates(response.data.data.data)
                    } else {
                        setEstates(originalList)
                    }
                }
                
            } catch (error) {
                console.log("error", error);
            }
             
            
			
        }
		
    }

    const hideSearchHandler = (e) => {
        setIsShowSearch(e)
    }


    useEffect(() => {
        getEstatesData()
    }, [pageNo])
    return <>
    <div className="m-3">
        
        
        {isShowSearch && <div class="card-header mb-3">
            <h3 >Estate</h3>
            <div class="row g-2">
                <div class="d-grid d-lg-block ">
                    <div className="d-flex justify-content-between">
                        <input type="search" placeholder="Search estate by name or email" onChange={(e) => handleSearch(e)} className="form-control" style={{ width: '280px' }} />
                        <NavLink to="/estate-farmers/add-estate" className="btn btn-primary" >Add Estate </NavLink>
                    </div>
                            
                </div>
            </div>
        </div>}
        

        {isLoading && <p>Please wait ..</p>}
        {!isLoading && <ListEstates 
        estates={estates} 
        count={totalCount} 
        pageNoHandler={pageNoHandler}
        hideSearch={hideSearchHandler}
        reloadEstates={reloadEstatesHandler}
         />}

    </div>

    </>
}

export default AllEstateFarmers;
import React, { useState, useEffect } from 'react';
import apiClient from '../../utils/axios';
import ReactPaginate from 'react-paginate';
import './SmsForm.css'

const SmsForm = () => {
    const [members, setMembers] = useState([]);
    const [originalMembers, setOriginalMembers] = useState([]);
    const [selectedMembers, setSelectedMembers] = useState([]);
    const [message, setMessage] = useState('');
    const [isLoading, setIsLoading] = useState(true);
    const[totalCount,setTotalCount] = useState(0)

    const [pageNo, setPageNo] = useState(1)
    const [limit, setLimit] = useState(10)
    const [coopId, setCoopId] = useState("000");
     //estates
    const [totalEstateCount,setTotalEstateCount] = useState()
    const [estates,setEstates] = useState([])
    const [originalEstateList,setOriginalEstateList] = useState([])

    const [showCooperatives, setShowCooperatives] = useState(false)
    const [showEstatesData,setShowEstatesData] = useState(false)     

    const [cooperatives,setCooperatives] = useState([])

    const [showSubmitMessage,setShowSubmitMessage] = useState(false)

    const [membersToShow,setMembersToShow] = useState([])

    const [isSubmitting,setIsSubmitting] = useState(false)

    const [error,setError] = useState("")
    const [successMessage, setSuccessMessage] = useState("")
        

    const [filter, setFilter] = useState({
        farmType: "",
        coopId: null,
    });
    
   
    // Fetch members from the API when the component mounts
    const handleSubmit = async (e) => {

        e.preventDefault();
        
        
        if (message.trim() === "") {
            setError("Enter a message to send");
            return;
        }
        
        if (message.trim().length > 163) {
            setError("Message cannot exceed 163 characters");
            return;
        }

      let data = {
          farmType: filter.farmType,
          coopId: filter.coopId === null ? "000" : filter.coopId,
          message: message,
          selectedMembers: selectedMembers,  
        }

        setIsSubmitting(true)
   
      try {
          const response = await apiClient.post(`v1/send-sms`, data);
          
          if (response.status === 200) {
             setIsSubmitting(false)
             setError("")
             setSuccessMessage("sms sent successfully")
             setSelectedMembers([])
             setMessage("")
             
             console.log("response is ok. sms sent",)
          }
      } catch (error) {
        console.log("error",error)
      }
    
    };
    
    const handleFarmChange = (e) => {
        let farmValue = e.target.value

        if(farmValue){
            setShowSubmitMessage(true)
        }else{
            setShowSubmitMessage(false)
            setShowEstatesData(false)
            setShowCooperatives(false)
        } 

        // if (farmValue !== filter.farmType && selectedMembers.length > 0) {
        //     console.log("you are switching to another Farm Type. this will reset the members selection");
        // }

        setFilter((prevFilter) => ({
            ...prevFilter,    // Keep the previous state
            farmType: farmValue  // Update farmType
        }));
        
        if (farmValue === "1") { //cooperative farmers
            setShowCooperatives(true)
            setShowEstatesData(false)
        }else if (farmValue === "2") { //estate farmers
            setShowCooperatives(false)
            setShowEstatesData(true)
              setFilter((prevFilter) => ({
                ...prevFilter,    // Keep the previous state
                coopId: ""        // Clear coopId if it's not a cooperative
            }));
        }  else {
            setSelectedMembers([])
            setShowCooperatives(false)
            setShowEstatesData(false)
        }
       

    }

    const handleCoopChange = async (e) => {
        let id = e.target.value;

        setCoopId(id)
         
        setPageNo(1)
        
        setFilter((prevFilter) => ({
            ...prevFilter,    // Keep the previous state
            coopId: id    // Update coopId
        }));

        if (!id) {
            return;
        } 

        if(id){
            await getMembersByCoopId(id,1,limit)
        }
       
    }

    const getMembersByCoopId = async (latestCoopId,pageNumber,limit) => {

        try {
            // Fetch members by cooperative ID
            let url = `v1/members-by-coop?pageNo=${pageNumber}&limit=${limit}&coopId=${latestCoopId}`
            const response = await apiClient.get(url)
            if (response.status === 200) {
                const newMembers = response.data.data.data;
                setMembers(newMembers);
                setOriginalMembers(newMembers)
                setTotalCount(response.data.totalCount)
            }
            
        } catch (error) {
            console.log('Error fetching members:', error);
        }
    }

    const getEstateFarms = async () => {
        let url = `v1/estate-farmers?pageNo=${pageNo}&limit=${limit}`
        try {
            const response = await apiClient.get(url)

            if (response.status === 200) {
                setTotalEstateCount(response.data.totalCount)
                setEstates(response.data.data.data)
                setOriginalEstateList(response.data.data.data)
            }
        } catch (error) {
            console.log(error)
        }
    }

    const handleMemberSelection = (memberId) => {
        
        let exists = selectedMembers.includes(memberId)
        if (exists) {
            setSelectedMembers(selectedMembers.filter((id) => id !== memberId));
        } else {
            setSelectedMembers([...selectedMembers, memberId]);
        }
        
    };

    const isMemberSelected = (memberId) => {
        return selectedMembers.includes(memberId);
    };


    const pageNoHandler = (number) =>{
        setPageNo(number.selected + 1)
    }

    const getData = async () => {
        try {
            const response = await apiClient.get(`v1/cooperatives`);
            setCooperatives(response.data)
        } catch (error) {
            console.log(error)
        }
    }

    const membersHandler = async (e) =>{
        let search = e.target.value
        
        let data = {
            search:search,
            coopId:coopId
        }

        try {
            const response = await  apiClient.post(`v1/members/search-member`,data)
            
            if(response.status === 200){
                let dataMembers = response.data.data.data;
                if(dataMembers.length > 0){
                    setMembers(response.data.data.data)
                    setTotalCount(response.data.totalCount)
                }else{
                    setMembers(originalMembers)
                    setTotalCount(response.data.totalCount)
                }
               
            }else{
                // console.log("test",response)
            }
        } catch (error) {
            console.log("error",error)
        }

    }
    // This function is used to show the members checked by fetching the members with the selected ids 
    const getMembersToShow = async () => {
        let data = {
            selectedMemberIds: selectedMembers
        }
        try {
            const response = await apiClient.post(`v1/get-members-by-ids`,data)
            if(response.status === 200){
                setMembersToShow(response.data.data.data)
            }
        } catch (error) {
            console.log("error",error)
        }
    }

    useEffect(() => {
        getData()
    }, [])
    
    useEffect(() => {
        getMembersByCoopId(coopId,pageNo,limit)
    },[pageNo,coopId])

    useEffect(()=>{
        if(selectedMembers.length > 0){
            getMembersToShow()
        }       
    },[selectedMembers])

    useEffect(()=>{
        getEstateFarms()
    },[])



  return (
      <div className="container mt-1">

        {filter.farmType === "1" && <div className='p-5 bg-primary fw-bold ' style={{color:'#fff'}}>Note: If you don't <b>select</b> any members, when you send a message, all the members of the Cooperatives will receive the SMS containing the message.
         </div>}
        
        <h2>Send SMS</h2>
          
        <div className='d-flex gap-2'>
              <select onChange={handleFarmChange} className='select-dropdown'>
                    <option value={``}>Select type of farmers</option>
                    <option value="1">Cooperative Farmers</option>
                    <option value="2">Estate Farmers</option>
              </select>

              {showCooperatives && <select onChange={handleCoopChange} className='select-dropdown'>
                        <option value=''>Select Cooperatives</option>
                   <option value='000'>All Cooperatives</option>
                  {cooperatives && cooperatives.length > 0 && cooperatives.map(cooperative => (
                      <option value={cooperative.id}>{cooperative.name}</option>
                  ))}
              </select>}
              <p></p>
              
              
        </div>
         
        {showCooperatives && !showEstatesData &&  members.length > 0 && <div>
              
                  <div  >
                  <h4 className='mb-2'>Search Members</h4>
                      <div className='mb-2 mt-2'>
                        <input type='text' name='search-members' placeholder='search member by Name' className='select-input'
                            onChange={membersHandler}
                        />

                            <h4 className='mb-2'>Select Members</h4>
                      </div>
                      
                      <table className="table table-responsive">
                          <thead>
                              <tr>
                                  <th>Select</th>
                                  <th>Member No</th>
                                  <th>Name</th>
                                  <th>Phone</th>
                              </tr>
                          </thead>
                          <tbody>
                                {members.map((member) => (
                                    <tr key={member.id}>
                                        <td>
                                            <input
                                                type="checkbox"
                                                value={member.id}
                                                onChange={() => handleMemberSelection(member.id)}
                                                checked={isMemberSelected(member.id)} // Keep checkbox checked if member is already selected
                                            />
                                        </td>
                                        <td>{member.member_no}</td>
                                        <td>{`${member.first_name} ${member.middle_name || ''} ${member.last_name}`}</td>
                                        <td>{member.phone}</td>
                                    </tr>
                                ))}
                            </tbody>
                      </table>
                       <div className="mt-3 mb-2 d-flex justify-content-start" >
                        <ReactPaginate
                                previousLabel={"previous"}
                                nextLabel={"next"}
                                breakLabel={"..."}
                                pageCount={(totalCount / 10)}
                                marginPagesDisplayed={3}
                                pageRangeDisplayed={3}
                                onPageChange={pageNoHandler}
                                containerClassName={"pagination justify-content-center"}
                                pageClassName={"page-item"}
                                pageLinkClassName={"page-link"}
                                previousClassName={"page-item"}
                                previousLinkClassName={"page-link"}
                                nextClassName={"page-item"}
                                nextLinkClassName={"page-link"}
                                breakClassName={"page-item"}
                                breakLinkClassName={"page-link"}
                                activeClassName={"active"}
                            />
                        </div>
                  </div>
              
        </div>}

        {/* this table data si for real estate famers only */}
        {showEstatesData && !showCooperatives && estates.length > 0 && <div className='mt-2'>
            <table className='table table-responsiveness'>
                <thead>
                    <tr>
                        <th>Estates Name</th>
                        <th>Estate Email</th>
                        <th>Estate Phone</th>
                    </tr>
                </thead>
                <tbody>
                    {estates.map((estate)=>(
                        <tr key={estate.id}>
                            <td>{estate.name}</td>
                            <td>{estate.email}</td>
                            <td>{estate.phone}</td>
                        </tr>
                    ))}
                </tbody>
                
            </table>
        </div>}
     
          <div className="form-group">
            <label htmlFor="message">Message</label>
            <textarea
              className="form-control"
              id="message"
              name="message"
              rows="4"
              placeholder="Enter your message"
              value={message}
              onChange={(e) => setMessage(e.target.value)}
            ></textarea>
          </div>

            
          
        {/* modal */}

        {showSubmitMessage && <button type="button" class="btn btn-primary mt-3" data-bs-toggle="modal" data-bs-target="#exampleModalCenter">
            Send SMS 
        </button>}
            
        <div class="modal fade" id="exampleModalCenter" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
            <div class="modal-header">
                          <h5 class="modal-title" id="exampleModalCenterTitle">SMS  {error && <span className='mt-3 text-danger fw-bold'>( {error} )</span>}</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close">
                {/* <span aria-hidden="true">&times;</span> */}
            </button>
            </div>
            <div class="modal-body">
                
                        {selectedMembers.length > 0 && filter.farmType === "1" && <p>This will send an sms to the following {selectedMembers.length} cooperative members </p>}
                        {selectedMembers.length > 0 && filter.farmType === "1" && <table className="table">
                            <thead>
                                <tr>
                                    
                                    <th>Name</th>
                                    <th>Phone</th>
                                    <th>Delete</th>
                                </tr>
                            </thead>
                        <tbody>
                            {membersToShow.map((member) => (
                                    <tr key={member.id}>
                                    <td>{`${member.first_name} ${member.middle_name || ''} ${member.last_name}`}</td>
                                        <td>{member.phone}</td>
                                        <td>
                                            <input
                                                type="checkbox"
                                                value={member.id}
                                                onChange={() => handleMemberSelection(member.id)}
                                                checked={isMemberSelected(member.id)} // Keep checkbox checked if member is already selected
                                            />
                                        </td>
                                    </tr>
                                )) }  
                            </tbody>
                            </table>
                          }
                          
                        
                          {selectedMembers.length === 0 && filter.farmType === "1" && <p>This will send an SMS to <b>all</b> Cooperative members </p>}
                          
                          

                    

                    {  filter.farmType === "2" &&<p>This will send an SMS to All Estate Farmers </p>}
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                <button type="submit" class="btn btn-primary" disabled={isSubmitting} onClick={handleSubmit} >Send Sms</button>

                <p>{!error && successMessage && successMessage }</p>
            </div>
        </div>
        </div>
        </div>
              
          
        {/* end modal */}

      
    </div>
  );
};

export default SmsForm;

import React, { useEffect, useRef } from "react";
import { useJsApiLoader } from "@react-google-maps/api";

// Define the libraries outside the component
const libraries = ["places", "geometry"];



const MapGenerator = ({jsonData}) => {
  const mapRef = useRef(null); // Reference to the map div

  const apiKey = "AIzaSyARoOpxrqkhArKg7xsyUaYOQqOlA2_bZMQ";
  // const apiKey = "AIzaSyB41DRUbKWJHPxaFjMAwdrzWzbVKartNGg";

  const { isLoaded, loadError } = useJsApiLoader({
    googleMapsApiKey: apiKey, // Ensure the API key is provided
    libraries: libraries, // Pass the static libraries array here
  });

  useEffect(() => {
    if (isLoaded && jsonData) {
      async function initMap() {
        const { Map } = await window.google.maps.importLibrary("maps");

        // Set initial map center to the first farm's coordinates
        const [firstFarm] = jsonData.farms;
        const [firstLat, firstLng] = firstFarm.gis_coordinates.split(',').map(Number);
        const centerPosition = { lat: firstLat, lng: firstLng };

        // Initialize the map, centered on the first farm
        const map = new Map(mapRef.current, {
          zoom: 10,
          center: centerPosition, // Center the map on the first farm's location
          mapId: "DEMO_MAP_ID", // Optional, can remove or replace with a valid map ID
        });

        // Loop through farms and create markers for each one
        jsonData.farms.forEach((farm, index) => {
          const [lat, lng] = farm.gis_coordinates.split(',').map(Number);
          const markerPosition = { lat, lng };

          // console.log(`Adding marker ${index + 1}: ${farm.member_name}`, markerPosition);

           new window.google.maps.Marker({
              position: markerPosition, // Use the extracted coordinates
              map: map, // Attach the marker to the map
              title: farm.member_name, // Use member name as marker title
          });
          
        });
      }

      initMap(); // Initialize the map
    }
  }, [isLoaded, jsonData]); // Ensure the effect runs when maps API and jsonData are loaded

  if (loadError) {
    return <div>Error loading maps</div>;
  }

  if (!isLoaded) {
    return <div>Loading maps...</div>;
  }
// file:///C:/xampp/htdocs/cofee-production-system/backend/resources/static-map.html
  return (
    <div
      ref={mapRef}
      style={{ width: "100%", height: "400px" }} // Set dimensions for the map
    ></div>
  );
};

export default MapGenerator;
